.fc .fc-toolbar-title {
  font-size: 1rem;
}

.fc .fc-daygrid-event {
  cursor: pointer;
}

.fc .fc-list-empty {
  background-color: transparent;
}

.fc-theme-standard .fc-list {
  border: none
}

@media screen and (min-width: 765px) {
  .fc .fc-toolbar-title {
    font-size: 1.5rem;
  }
}